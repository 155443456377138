<template>
    <div v-if="loggedIn">
        <div>
            <ProtectedLink :linkType = "'button'" :text="'Click here if you are not automatically redirected to your file.'" :classes="'btn btn-lg btn-link pointer'" :link="id" :query-string="''" :id="id" ref="downloadLink" :thisWindow="true" :type="'download'"/>
        </div>
    </div>
    <div v-else-if="!loggedIn">
        <div>
            <SignInPage></SignInPage>
        </div>
    </div>
</template>

<script>

import ProtectedLink from "@/components/utils/ProtectedLink";
import {store} from "@/store/Report.store";
import SignInPage from "@/views/SignInPage";
export default {
    name: "DownloadPage",
    components: {
        ProtectedLink,
        SignInPage

    },
    props: ['id'],
    mounted() {
        this.$refs.downloadLink.redirect()
    },
    data() {
        return {
            state: store.state,
            appState: this.$appStore.state,
        }
    },
    computed: {
        loggedIn: function() {
            return this.appState.loggedIn
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div v-if="loggedIn">
        <div class="fullWidth">
            <Nav v-show="state.showNav" />
            <main :class="(state.showNav) ? 'col-lg-10 col-md-9 col-sm-9 left_gutter main fill' : 'col-xs-12'">
                <component v-bind:is="currentComponent" :action="state.tabAction"></component>
            </main>
        </div>
    </div>
    <div style="margin-left: 175px;" v-else-if="!loggedIn">
        <div>
            <SignInPage></SignInPage>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Nav from '@/components/reports/utils/Nav.vue'
    import SignInPage from "@/views/SignInPage";
    
    export default {
        name: "ReportPage",
        components: {
            SignInPage,
            Nav,
            aging: () => import('@/components/reports/aging.vue'),
            automation_log: () => import('@/components/reports/automation_log'),
            asset_rpt: () => import('@/components/reports/asset_rpt.vue'),
            attendance: () => import('@/components/reports/attendance.vue'),
            batch_utilization: () => import('@/components/reports/batch_utilization.vue'),
            build_progress: () => import('@/components/reports/build_progress.vue'),
            brand_sales: () => import('@/components/reports/brand_sales.vue'),
            callahead: () => import('@/components/reports/CallAheads/callahead.vue'),
            clock_in_out_ips: () => import('@/components/reports/clock_in_out_ips'),
            crm_rpt: () => import('@/components/reports/crm_rpt'),
            customer_summary: () => import('@/components/reports/customer_summary'),
            credit_rpt: () => import('@/components/reports/credit_rpt.vue'),
            daily_curb_count: () => import('@/components/reports/daily_curb_count.vue'),
            daily_invoiced: () => import('@/components/reports/daily_invoiced/container.vue'),
            deliveries: () => import('@/components/reports/deliveries.vue'),
            drawing_requests: () => import('@/components/reports/drawing_requests.vue'),
            employee_photos: () => import('@/components/reports/employee_photos.vue'),
            fifo_rpt: () => import('@/components/reports/fifo_rpt.vue'),
            first_clock_in: () => import('@/components/reports/first_clock_in.vue'),
            freight: () => import('@/components/reports/freight.vue'),
            hours: () => import('@/components/reports/hours.vue'),
            item_freight: () => import('@/components/reports/item_freight.vue'),
            job: () => import('@/components/reports/job.vue'),
            jobcosting: () => import('@/components/reports/jobcosting.vue'),
            order_progress: () => import('@/components/reports/OrderProgress/sales_goals.vue'),
            curb_sales: () => import('@/components/reports/curb_sales.vue'),
            curbs_shipped: () => import('@/components/reports/curbs_shipped.vue'),
            //jobcosting: () => import('@/components/reports/jobcosting.vue'),
            labor: () => import('@/components/reports/labor.vue'),
            labor_rate: () => import('@/components/reports/labor_rate.vue'),
            maintenance: () => import('@/components/reports/maintenance.vue'),
            raw_material_inventory: () => import('@/components/reports/raw_material_inventory.vue'),
            tracker_mer_rpt: () => import('@/components/reports/tracker_mer_rpt.vue'),
            morale: () => import('@/components/reports/morale.vue'),
            delivery_ontime: () => import('@/components/reports/delivery_ontime.vue'),
            payment_rpt: () => import('@/components/reports/payment_rpt.vue'),
            production_sales_value: () => import('@/components/reports/production_sales_value.vue'),
            purchase_order_rpt: () => import('@/components/reports/purchase_order_rpt.vue'),
            quote_rpt: () => import('@/components/reports/quote_rpt.vue'),
            recognition: () => import('@/components/reports/recognition.vue'),
            remake: () => import('@/components/reports/remake.vue'),
            rework: () => import('@/components/reports/rework.vue'),
            sales: () => import('@/components/reports/sales.vue'),
            sales_incentive: () => import('@/components/reports/sales_incentive.vue'),
            sales_rebate: () => import('@/components/reports/sales_rebate.vue'),
            sales_rebate_companies: () => import('@/components/reports/sales_rebate_companies.vue'),
            sales_revenue: () => import('@/components/reports/sales_revenue.vue'),
            sales_scorecard: () => import('@/components/reports/sales_scorecard.vue'),
            scorecard: () => import('@/components/reports/scorecard.vue'),
            scrap_tracking: () => import('@/components/reports/scrap_tracking.vue'),
            ship_manifest: () => import('@/components/tools/ShippingManifest'),
            ship_paid: () => import('@/components/tools/ShipPayments'),
            shipping: () => import('@/components/reports/shipping.vue'),
            //job: () => import('@/components/reports/job.vue'),
            shoptime: () => import('@/components/reports/shoptime.vue'),
            stock: () => import('@/components/reports/stock.vue'),
            stock_movement: () => import('@/components/reports/stock_movement.vue'),
            taxable: () => import('@/components/reports/taxable.vue'),
            timecard_rpt: () => import('@/components/reports/timecard_rpt.vue'),
            timecard_current: () => import('@/components/reports/timecard_current.vue'),
            unverified_quotes_rpt: () => import('@/components/reports/unverified_quotes_rpt.vue'),
            user_rpt: () => import('@/components/reports/user_rpt.vue'),
            wip_value: () => import('@/components/reports/wip_value.vue'),
        },
        data() {
            return {
                state: store.state,
                appState: this.$appStore.state,
            }
        },
        computed: {
            currentComponent: function() {
                return this.reportName;
            },
            loggedIn: function() {
                return this.appState.loggedIn
            }
        },
        props: ['reportName'],
        /*beforeRouteEnter (to, from, next){
            const canAccess = helpers.canAccess(Pages.PAGES.reports[to.params.reportName], appStore.state);
            if (canAccess){
                next();
            }else {
                next('/');
            }
        },
        beforeRouteUpdate (to, from, next){
            const canAccess = helpers.canAccess(Pages.PAGES.reports[to.params.reportName], appStore.state);
            if (canAccess){
                next();
            }else {
                next('/');
            }
        }*/
    }
</script>

<style scoped>

</style>
<template>
    <div>
        <nav v-if="!state.modernized" class="nav_side col-lg-1 col-md-1 col-sm-1">
            <ul id="navUl" class="nav nav-pills nav-stacked">
                <li class="cdi_tab active"><a @click="runReport">Run Report</a></li>
                <li class="cdi_tab">
                  <a><ProtectedLink :queryString="queryString" :linkType="'link'" :text="'PDF Report'" :name="state.report" :type="'reportPDF'" /></a>
                </li>
                <li class="cdi_tab">
                   <a><ProtectedLink :queryString="queryString" :linkType="'link'" :text="'CSV Report'" :name="state.report" :type="'reportCSV'" /></a>
                </li>
                <li class="cdi_tab">
                    <a><ProtectedLink :queryString="queryString" :linkType="'link'" :text="'To Drive'" :name="state.report" :type="'reportToDrive'" /></a>
                </li>
                <li class="cdi_tab" v-if="state.report === 'sales' || state.report === 'user_rpt'">
                    <a><ProtectedLink :queryString="queryString" :linkType="'link'" :text="'Print Labels'" :name="state.report" :type="'printLabels'" /></a>
                </li>
                <li v-for="(view, view_id) in state.extraTabs" v-bind:key="view_id" class="cdi_tab">
                    <a @click="setAction(view.action)">
                        {{ view.label }}
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import ProtectedLink from "@/components/utils/ProtectedLink";
    import helpers from "@/store/helpers";

    export default {
        name: "Nav",
        components:{
          ProtectedLink
        },
        data() {
            return {
                state: store.state
            }
        },
        methods: {
            runReport: function () {
                this.setAction('runReport');
                if (!this.state.modernized) {
                    store.runReport();
                }
            },
            setAction: function (action) {
                store.state.tabAction = action;
            }
        },
        computed:{
            queryString: function() {
                var qsPairs = helpers.getQSPairs(this.state.optionFields);

                qsPairs['params[json]'] = 1;
                if (!this.state.isGroupBy) {
                    qsPairs['params[is_group_by]'] = 0;
                    qsPairs['params[fields]'] = this.state.data;
                }else{
                    qsPairs['params[is_group_by]'] = 1;
                    qsPairs['params[group_by]'] = this.state.groupBy;
                }

                if (this.state.isCompareBy) {
                    qsPairs['params[date_comparison]'] = 'on';
                    qsPairs['params[period_type]'] = this.state.optionFields.compareByPeriod.val;
                    qsPairs['params[date_compare_periods]'] = this.state.optionFields.numPeriod.val;
                }

                return helpers.getQSString(qsPairs);

            }
        }
    }
</script>

<style scoped>
.nav_side{
    min-width: 165px!important;
}
    ul.nav > li {
        cursor: pointer;
    }
    a{
        padding: 10px 15px;
        position: relative;
        display: block;
        min-width: 145px !important;
    }
    a:hover {
        text-decoration: none;
        background-color: #e5e8ed;
        border: 1px solid #d4d8dd;
        border-radius: 4px;
    }

    #navUl{
        display: inline;
    }
</style>
import http from "axios";
import helpers from './helpers';
import { cdiVars } from '@/cdiVars';
import appStore from "@/store/App.store";

export const store = {
    state: {
        report: '',         // report file name
        isGroupBy: false,   // if the query should be a group by
        isCompareBy: false,  // if the query is automatically comparing
        groupBy: [],        // fields to group by
        data: [],           // data fields to query
        optionFields: {},   // fields to filter on
        groupByFields: {},  // available group by fields
        dataFields: {},     // available data fields to query
        results: {},        // results data (to be phased out after full conversion to JSON)
        jsonResult: {
            slot1: {},
        },     // results data on JSON implementations
        runOnLoad: true,    // whether a report runs when it is initialized
        extraTabs: [],      // any custom tabs in the left-hand navigation
        tabAction: null,     // action injected into a custom tab for children to use
        modernized: false,  // Front end flag corresponding to server version to handle JSON instead of HTML
        list: [],
        groupByList: [],
        fields: [],
        toggleHide: true,
        showNav: true,
    },


    setReport: function (rpt, optionFields, groupByFields, dataFields, modernized = false, showNav = true) {
        this.state.showNav = showNav;
        this.results = {};
        this.state.report = rpt;
        this.state.modernized = modernized;
        this.state.optionFields = optionFields;
        this.state.optionFields.orderBy = {val: '', qsField: '[orderby]'};
        this.state.optionFields.direction = {val: '', qsField: '[direction]'};
        this.state.groupByFields = groupByFields;
        this.state.dataFields = dataFields;
        this.state.data = this.getDefaults(this.state.dataFields);
        this.state.groupBy = this.getDefaults(this.state.groupByFields);
        this.state.list = this.getList(this.state.dataFields)
        this.state.groupByList = this.getList(this.state.groupByFields)
        this.state.isGroupBy = this.getGroupByDefault(this.state.groupByFields);
        if (this.state.runOnLoad) {
            this.runReport();
        }

        this.state.runOnLoad = true;
        this.state.extraTabs = [];
    },
    getDefaults: function (fields) {
        var defaults = [];
        for (const field in fields){
            if (fields[field].checked == true) {
                defaults.push(field);
            }
        }
        return defaults;
    },

    getGroupByDefault: function (fields) {

        for (const field in fields){
            return fields[field].checked === true;
        }
    },

    getList: function(fields) {
        var list = [];
        for (const field in fields){
            if (fields[field]) {
                list.push(field);
            }
        }
        return list;
    },


    runReport: async function (reportFormat = null, payloadTag = null, optFields = {}) {
        if (this.modernized && (!reportFormat || !payloadTag)) {
            return;
        }

        let optionFields = {};
        if (Object.entries(optFields).length) {
            optionFields = Object.entries(optFields);
        } else {
            optionFields = Object.entries(this.state.optionFields);
        }

        if (reportFormat) {
            optionFields.push(['reportFormat', {val: reportFormat, qsField: '[reportFormat]'}]);
        }

        if (payloadTag) {
            optionFields.push(['payloadTag', {val: payloadTag, qsField: '[payloadTag]'}]);
        }

        const defaultOptionFields = [];

        if (this.state.toggleHide === true) {
            optionFields.forEach(optionField => {
                if (typeof optionField[1].defaultVal !== 'undefined') {
                    optionField[1].val = optionField[1].defaultVal;
                }
                defaultOptionFields.push(optionField);
            })
            this.state.optionFields = Object.fromEntries(defaultOptionFields);
        }
        let url = `${cdiVars.API_REPORT_URL}/${this.state.report}/to_html`;
        var qsPairs = helpers.getQSPairs(this.state.optionFields);

        qsPairs['params[json]'] = 1;
        if (!this.state.isGroupBy) {
            qsPairs['params[is_group_by]'] = 0;
            qsPairs['params[fields]'] = this.state.data;
        } else {
            qsPairs['params[is_group_by]'] = 1;
            qsPairs['params[group_by]'] = this.state.groupBy;
        }

        if (this.state.isCompareBy) {
            qsPairs['params[date_comparison]'] = 'on';
            qsPairs['params[period_type]'] = this.state.optionFields.compareByPeriod.val;
            qsPairs['params[date_compare_periods]'] = this.state.optionFields.numPeriod.val;
        }

        let qsString = helpers.getQSString(qsPairs);
        appStore.loadingMessage();
        return await http.post(url, qsString, {headers: helpers.authHeader()})
            .then(res => {
                appStore.successBox();
                if (res.data.opt == 'denied') {
                    appStore.autoSignOut();
                } else {
                    if (this.state.modernized) {
                        this.state.jsonResult[res.data.payloadTag] = res.data;
                    } else {
                        this.state.results[res.data.rpt] = res.data
                    }
                }
            })
            .catch();
    },

    post: async function (url, qsPairs, resultField) {
        appStore.loadingMessage();
        let qsString = helpers.getQSString(qsPairs);
        return await http.post(url, qsString, {headers: helpers.authHeader()})
            .then(res => this.handleResponse(resultField, res.data))
            .catch();
    },
    /**
     * if resultField is a string, this.state.string is updated with the data
     * if resultField is undefined, the data is returned
     */
    handleResponse: function (resultField, data) {
        if (data.opt == 'denied') {
            appStore.autoSignOut();
        } else if (data.opt == 'error') {
            appStore.errorBox(data.msg);
        } else {
            appStore.successBox(data.msg);
            if (typeof resultField === 'undefined') {
                return data;
            } else {
                helpers.nestedSet(this.state, resultField, data);
            }
        }
    },
    api: function (endPoint, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}/${this.state.name}/${endPoint}/${this.state.object.id}`, options, resultField))
        );
    },

    customAPI: async function (endPoint, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}/${endPoint}`, options, resultField))
        );
    },
    customReport: function (report, endPoint, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_REPORT_URL}/${report}/${endPoint}`, options, resultField))
        );
    },
    sortReport: function (field) {
        appStore.loadingMessage();
        if (this.state.optionFields.orderBy.val == field) {
            this.state.optionFields.direction.val = (this.state.optionFields.direction.val == 'DESC') ? '' : 'DESC'
        } else {
            this.state.optionFields.orderBy.val = field;
            this.state.optionFields.direction.val = '';
        }
        this.runReport();
    },

    setGroupByFields: function (e) {
        this.state.groupBy = this.setFields(e, this.state.groupBy);
    },
    setDataFields: function (e) {
        this.state.data = this.setFields(e, this.state.data);
    },
    setFields: function (e, fields) {
        var input = e.target;
        if (input.checked) {
            fields.push(input.value);
        } else {
            const index = fields.indexOf(input.value);
            if (index > -1) {
                fields.splice(index, 1);
            }
        }

        if (this.state.isGroupBy) {
            let list = this.state.groupByList;
            let filteredList = list.filter(function(item) {
                return fields.indexOf(item) !== -1;
            });
            fields = filteredList;
        } else {
            let list = this.state.list;
            let filteredList = list.filter(function(item) {
                return fields.indexOf(item) !== -1;
            });
            fields = filteredList;
        }

        return fields;
    },
    resetTabAction: function () {
        this.state.tabAction = null;
    },
    windowSubmit: function (url, apiType = 'rapi') {
        if (apiType == 'api' || apiType == 'rapi') {
            let options = helpers.getQSString(
                helpers.getQSPairs(this.state.optionFields)
            );

        let base = process.env.VUE_APP_URL;
        window.open(`${base}/${apiType}/${this.state.report}/${url}?${options}&genid=${Math.floor(Math.random() * 101)}&params[token]=${appStore.getUserFileAccessToken()}`);
    }
    }
}
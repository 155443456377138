<template>
    <div :class='(displayInline) ? "inline_block" : ""'>
        <a :href="fullLink" v-if="linkType === 'link'" :target="targetWindow" :class="classes">{{ text }}</a>
        <a :href="fullLink" v-else-if="linkType === 'button'" :target="targetWindow" :class="classes">{{ text }}</a>
        <a :href="fullLink" v-else-if="linkType === 'icon'" :target="targetWindow"><span :class="classes"></span></a>
        <a :href="fullLink" v-else-if="linkType === 'iconText'" :target="targetWindow"><span :class="classes"></span>{{ text }}</a>
        <a :href="fullLink" v-else-if="linkType === 'image'" :target="targetWindow"><img :src="imgSrc" :class="classes" /></a>
        <a :href="fullLink" v-else-if="linkType === 'imgText'" :target="targetWindow"><img :src="imgSrc" :class="classes" /><br />{{ text }}</a>
    </div>
</template>

<script>

export default {
    name: 'ProtectedLink',
    props: [
        'link',
        'type',
        'id',
        'queryString',
        'name',
        'thisWindow',
        'displayInline',
        'linkType',
        'disabled',
        'text',
        'classes',
        'imgSrc'
    ],

    data() {
        return {
            downloadURL: this.$cdiVars.DOWNLOAD_URL,
            apiUrl: this.$cdiVars.API_URL,
            rapiUrl: this.$cdiVars.API_REPORT_URL
        }
    },

    computed: {
        fullLink: function () {
            let string = '';
            switch (this.type) {
                case 'trackerInvoice':
                    string = `${this.apiUrl}/tracker_invoice_pdf/stream_invoice/Invoices`
                    string += `?params[file]=${this.link}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'credit':
                    string = `${this.apiUrl}/credit/to_pdf/${this.id}?${this.queryString}&params[to_file]=1&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'po':
                    string = `${this.apiUrl}/po/to_pdf/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'material':
                    string = `${this.apiUrl}/material/to_pdf/${this.id}?params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'drawing':
                    string = `${this.downloadURL}/file/${this.id}?${this.queryString}&token=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'quote':
                    string = `${this.apiUrl}/quote_pdf/stream/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'reportPDF':
                    string = `${this.rapiUrl}/${this.name}/to_pdf?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'reportCSV':
                    string = `${this.rapiUrl}/${this.name}/to_csv?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'reportToDrive':
                    string = `${this.rapiUrl}/${this.name}/to_drive?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'printLabels':
                    string = `${this.rapiUrl}/${this.name}/print_labels?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'invoicePDF':
                    string = `${this.apiUrl}/tracker_pdf/invoice/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'invoiceEmail':
                    string = `${this.apiUrl}/tracker_pdf/invoice/${this.id}?${this.queryString}&params[email]=1&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'download':
                    string = `${this.downloadURL}/file/${this.id}?${this.queryString}&token=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'preMeasure':
                    string = `${this.apiUrl}/quote_pre_measure_sheet_pdf/stream/${this.id}?params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'itemStickers':
                    string = `${this.apiUrl}/curb_stickers/to_pdf/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'itemBarcodes':
                    string = `${this.apiUrl}/curb_stickers_nomenclature/to_pdf/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'coverSheets':
                    string = `${this.apiUrl}/cover_sheets/to_pdf/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'shippingLabel':
                    string = `${this.apiUrl}/shipping_labels/to_pdf/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'shippingPaperwork':
                    string = `${this.apiUrl}/shipping_paperwork/to_pdf/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                case 'spring_configuration':
                    string = `${this.apiUrl}/curb_springs_pdf/stream/${this.id}?${this.queryString}&params[token]=${ this.$appStore.getUserFileAccessToken() }`;
                    break;
                default:
                    string = `${this.downloadURL}/${this.link}`;
                    string += `?token=${ this.$appStore.getUserFileAccessToken() }`;
            }
            return string;
        },
        targetWindow: function() {
            return (this.thisWindow) ? '_self' : '_blank'
        },
    }
}
</script>

<style scoped>
img{
    max-width: 400px;
}
</style>